
<template>
  <v-container fluid>
    <GhostRatingIndex/>
  </v-container>
</template>

<script>


export default {
  name: 'GhostRating',
  components: {
    GhostRatingIndex: () => import ('../../components/ghost_rating/GhostRatingIndex.vue'),
  },
}
</script>

<style scoped>

</style>